






import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class Btn extends Vue {
  @Prop({ default: 'bg-gray-500' }) bg!: string
  @Prop({ default: 'text-white' }) color!: string
  @Prop({ default: '' }) className!: string
  @Prop({ default: 'md' }) size!: 'ty' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

  get sizeCls(): string {
    return {
      ty: 'py-0 px-0 text-ty h-5 justify-center',
      xs: 'py-0 px-0 text-xs h-6 justify-center',
      sm: 'py-1 px-1 text-sm h-8 justify-center',
      md: 'py-1 px-2 text-base h-8 justify-center',
      lg: 'py-3 px-4 text-lg h-12 justify-center',
      xl: 'py-4 px-16 text-2xl h-16 justify-center',
      xxl: 'py-24 px-32 text-4xl h-24 justify-center'
    }[this.size]
  }
}
