
































































import { Component, Vue } from 'vue-property-decorator'
import { formatPhoneNumber } from '../../shared/src/helpers/numFormat'
import Card from '../../shared/src/components/Card.vue'
import Col from '../../shared/src/components/Col.vue'
import Row from '../../shared/src/components/Row.vue'
import Btn from '../../shared/src/components/Btn.vue'
import Logo from '../components/Logo.vue'
import Sizzle from '../components/Sizzle.vue'
import ContentRow from '../components/ContentRow.vue'

@Component({
  components: {
    Sizzle,
    Card,
    Col,
    Row,
    Btn,
    Logo,
    ContentRow
  }
})
export default class Home extends Vue {
  footerNav = this.$store.state.footerNav
  phone = this.$store.state.phone
  formatPhoneNumber = formatPhoneNumber
}
