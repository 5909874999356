




import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Logo extends Vue {
  @Prop({ default: 'md' }) size!: 'sm' | 'md' | 'lg'
  w: number = 200
  h: number = 91
  get width() {
    return this.size === 'sm'
      ? this.w * 0.5
      : this.size === 'md'
      ? this.w
      : this.size === 'lg'
      ? this.w * 1.5
      : this.w * 2
  }
  get height() {
    return this.size === 'sm'
      ? this.h * 0.5
      : this.size === 'md'
      ? this.h
      : this.size === 'lg'
      ? this.h * 1.5
      : this.h * 2
  }
}
