














import { Vue, Component, Prop } from 'vue-property-decorator'
import { ScopedSlotChildren } from 'vue/types/vnode'
import Row from './Row.vue'

@Component({
  components: {
    Row
  }
})
export default class Bar extends Vue {
  @Prop({ default: null }) left!: ScopedSlotChildren
  @Prop({ default: null }) center!: ScopedSlotChildren
  @Prop({ default: null }) right!: ScopedSlotChildren
}
