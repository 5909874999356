

































































import { Vue, Component, Prop } from 'vue-property-decorator'
import Col from '../../shared/src/components/Col.vue'
import Row from '../../shared/src/components/Row.vue'

@Component({
  components: {
    Col,
    Row
  }
})
export default class Footer extends Vue {
  year = new Date().getFullYear()
  links = this.$store.state.footerNav
}
