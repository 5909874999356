













import { Vue, Component, Prop } from 'vue-property-decorator'
import Card from '../../shared/src/components/Card.vue'
import Col from '../../shared/src/components/Col.vue'
import Row from '../../shared/src/components/Row.vue'
import Logo from '../components/Logo.vue'
import SportsNav from '../components/SportsNav.vue'

@Component({
  components: {
    Card,
    Col,
    Row,
    Logo,
    SportsNav
  }
})
export default class Sizzle extends Vue {
  @Prop({ default: false }) showCards!: boolean
}
