const pad = (value: number | string, packChar: string, length: number, after?: boolean): string => {
  if (!length) {
    return ''
  }
  let str = value + ''
  while (str.length < length) {
    str = after ? str + packChar : packChar + str
  }
  return str
}

export const rpad = (value: number | string, packChar: string, length: number): string => {
  return pad(value, packChar, length, true)
}

const lpad = (value: number | string, packChar: string, length: number): string => {
  return pad(value, packChar, length)
}
export default lpad
