








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Icon extends Vue {
  @Prop({ default: 'auto' }) size!: 'auto' | 'ty' | 'sm' | 'md' | 'lg'
  @Prop({ default: false }) none!: boolean
  title = ''
  name = 'icon'
  w = 0
  h = 0
  get width() {
    return this.size === 'ty'
      ? this.w * 0.25
      : this.size === 'sm'
      ? this.w * 0.5
      : this.size === 'md'
      ? this.w
      : this.size === 'lg'
      ? this.w * 1.5
      : this.w * 2
  }
  get height() {
    return this.size === 'ty'
      ? this.w * 0.25
      : this.size === 'sm'
      ? this.h * 0.5
      : this.size === 'md'
      ? this.h
      : this.size === 'lg'
      ? this.h * 1.5
      : this.h * 2
  }
}
