import { rpad } from './lpad'

export const formatPrice = (value: number, decimals = 2): string => {
  return '$' + formatFloat(value, decimals)
}

export const formatNumber = (value: number, ceil?: boolean): string => {
  return formatFloat(value, 0, ceil)
}

export const formatPercent = (value: number, decimals = 2): string => {
  return (value * 100).toFixed(decimals) + '%' // using formatFloat isn't accurate
}

const numberCommasRx = /\B(?=(\d{3})+(?!\d))/g
export const formatFloat = (value: number, decimals: number, ceil?: boolean): string => {
  if (!value) {
    value = 0
  }
  const s = rpad(1, '0', decimals + 1)
  const n = parseInt(s, 10)
  const val = ceil ? Math.ceil(value * n) / n : Math.round(value * n) / n
  const parts = val.toString().split('.')
  return parts[0].replace(numberCommasRx, ',') + (parts[1] ? '.' + parts[1] : '')
}

export const formatInt = (value: number, ceil?: boolean): string => {
  return formatFloat(value, 0, ceil)
}

export const formatPhoneNumber = (str: string): string => {
  //Filter only numbers from the input
  if (!str) {
    return ''
  }
  const cleaned = ('' + str).replace(/\D/g, '')

  //Check if the input is of correct
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }

  return `(${cleaned[0] || 0}${cleaned[1] || 0}${cleaned[2] || 0})-${cleaned[3] || 0}${cleaned[4] || 0}${
    cleaned[5] || 0
  }-${cleaned[6] || 0}${cleaned[7] || 0}${cleaned[8] || 0}${cleaned[9] || 0}`
}
