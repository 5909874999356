import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const defaultNav = [
  { label: 'Home', path: '/' },
  { label: 'Company', path: '/company' },
  { label: 'Construction', path: '/construction' },
  { label: 'Surfacing', path: '/surfacing' },
  { label: 'Gallery', path: '/gallery' },
  { label: 'Colors', path: '/colors' }
]

const store = new Vuex.Store({
  state: {
    title: 'All-Courts',
    // phone: '8018502404',
    phone: '8016585656',
    address: {
      street: '516 E Lakeview Pkwy Provo, UT 84606',
      city: 'Springville',
      state: 'UT',
      zip: '84663'
    },
    headerNav: defaultNav,
    footerNav: [
      ...defaultNav,
      { label: 'Pickleball', path: '/pickleball' },
      { label: 'Tennis', path: '/tennis' },
      { label: 'Basketball', path: '/basketball' },
      { label: 'Volleyball', path: '/volleyball' },
      { label: 'Multi-Sport', path: '/multicourt' },
      { label: 'Lighting', path: '/lighting' },
      { label: 'Fencing', path: '/fencing' },
      { label: 'Residential', path: '/residential' },
      { label: 'Commercial', path: '/commercial' },
      { label: 'Court Scapes', path: '/court-scapes' },
      { label: 'Careers', path: '/careers' },
      { label: 'Contact Us', path: '/contact' }
    ],
    courts: ['Pickleball', 'Tennis', 'Multi-Sport', 'Basketball', 'Volleyball', 'Residential', 'Commercial']
  },
  mutations: {},
  actions: {}
})

export default store
