
































import { Vue, Component, Prop } from 'vue-property-decorator'
import Col from '../shared/src/components/Col.vue'
import Row from '../shared/src/components/Row.vue'
import Bar from '../shared/src/components/Bar.vue'
import Btn from '../shared/src/components/Btn.vue'
import MenuIcon from '../shared/src/components/Icon/MenuIcon.vue'
import Logo from './components/Logo.vue'
import Footer from './components/Footer.vue'
import PhoneIcon from './components/PhoneIcon.vue'
import { formatPhoneNumber } from '../shared/src/helpers/numFormat'

@Component({
  components: {
    Col,
    Row,
    Bar,
    Btn,
    Logo,
    MenuIcon,
    PhoneIcon,
    Footer
  }
})
export default class AppRoot extends Vue {
  get phone(): string {
    return this.$store.state.phone
  }
  get headerNav(): void {
    return this.$store.state.headerNav
  }

  formatPhoneNumber = formatPhoneNumber
}
