
import store from '@/store'
import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from './Icon.vue'
export default class PhoneIcon extends Icon {
  @Prop({ default: `Call ${store.state.phone}` }) title!: string
  w = 24
  h = 24
  name = 'phone'
}
