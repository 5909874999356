import Vue from 'vue'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag'
import './tailwind.css'

// import './helpers/sitemap'

Vue.use(VueCookies)

if (process.env.NODE_ENV === 'production') {
  // Vue.use(VueGtag, { config: { id: 'G-VHVFSSKDY0' } }, router)
  Vue.use(VueGtag, { config: { id: 'UA-187950908-1' } }, router)
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
