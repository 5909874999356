




import { Vue, Component, Prop } from 'vue-property-decorator'
import Col from './Col.vue'

@Component({
  components: {
    Col
  }
})
export default class Card extends Vue {}
