















































import { Vue, Component, Prop } from 'vue-property-decorator'
import Card from '../../shared/src/components/Card.vue'
import Col from '../../shared/src/components/Col.vue'
import Row from '../../shared/src/components/Row.vue'
import PickleballIcon from './PickleballIcon.vue'
import TennisIcon from './TennisIcon.vue'
import BasketballIcon from './BasketballIcon.vue'
import VolleyballIcon from './VolleyballIcon.vue'
import MultiSportIcon from './MultiSportIcon.vue'
import ResidentialIcon from './ResidentialIcon.vue'
import CommercialIcon from './CommercialIcon.vue'

@Component({
  components: {
    Card,
    Col,
    Row,
    PickleballIcon,
    TennisIcon,
    BasketballIcon,
    VolleyballIcon,
    MultiSportIcon,
    ResidentialIcon,
    CommercialIcon
  }
})
export default class SportsNav extends Vue {
  card = 'm-1 p-2 lg:m-2 lg:p-4 card-bg min-w-min min-h-min'
  icon = 'w-10 h-12 sm:w-12 sm:h-14 md:w-14 md:h-16'
  text = 'font-display hidden md:flex md:text-sm uppercase w-14 lg:w-16'
}
