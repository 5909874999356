import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import pageTitle from '../../shared/src/helpers/pageTitle'
import { page } from 'vue-analytics'
import hop from '../../shared/src/helpers/hop'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      priority: 1,
      title: 'All-Courts | Multipurpose Court Construction, Surfacing, Lighting, Fencing',
      metaTags: [
        {
          name: 'description',
          content:
            'All-Courts is your all-in-one option for multipurpose court construction, surfacing, lighting, and fencing. Learn more on our website!'
        },
        {
          property: 'keywords',
          content:
            'tennis court resurfacing, tennis court resurfacing near me, tennis court resurfacing companies near me, basketball court resurfacing, tennis court resurfacing contractors near me, netball court resurfacing, pickleball court resurfacing, tennis court repair and resurfacing, tennis court resurfacing companies, outdoor tennis court resurfacing, basketball court resurfacing near me, court resurfacing, court resurfacing near me, indoor basketball court resurfacing, outdoor basketball court resurfacing, resurfacing tennis court contractors, shuffleboard court resurfacing, shuffleboard court resurfacing companies, sport court resurfacing, tennis court resurfacing contractors'
        }
      ]
    }
  },
  {
    path: '/company',
    name: 'company',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Company" */ '../views/Company.vue'),
    meta: {
      priority: 0.8,
      title: 'All-Courts | Backyard Multi Sport Court Construction, Surfacing, Lighting, Fencing',
      metaTags: [
        {
          name: 'description',
          content:
            'All-Courts specializes in commercial or backyard multi sport court construction, surfacing, lighting and fencing. Visit our website!'
        },
        {
          name: 'keywords',
          content:
            'tennis court resurfacing, tennis court resurfacing near me, tennis court resurfacing companies near me, basketball court resurfacing, tennis court resurfacing contractors near me, netball court resurfacing, pickleball court resurfacing, tennis court repair and resurfacing, tennis court resurfacing companies, outdoor tennis court resurfacing, basketball court resurfacing near me, court resurfacing, court resurfacing near me, indoor basketball court resurfacing, outdoor basketball court resurfacing, resurfacing tennis court contractors, shuffleboard court resurfacing, shuffleboard court resurfacing companies, sport court resurfacing, tennis court resurfacing contractors'
        }
      ]
    }
  },
  {
    path: '/construction',
    name: 'construction',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Construction" */ '../views/Construction.vue'),
    meta: {
      priority: 0.9,
      title: 'All-Courts | Multi Sport Court Construction',
      metaTags: [
        {
          name: 'description',
          content:
            'The "how" really matters. Find out why All-Courts multi sport court construction is better. Visit our website.'
        },
        {
          name: 'keywords',
          content:
            'tennis court construction, pickleball court construction, tennis court construction company, basketball court construction, elite court construction, tennis court construction near me, athletic court construction, tennis court construction companies near me, tennis court construction contractors, outdoor volleyball court construction, outdoor shuffleboard court construction, outdoor badminton court construction cost, outdoor basketball court construction, outdoor tennis court construction'
        }
      ]
    }
  },
  {
    path: '/surfacing',
    name: 'surfacing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Surfacing" */ '../views/Surfacing.vue'),
    meta: {
      priority: 0.9,
      title: 'All-Courts | Athletic Court Surfacing',
      metaTags: [
        {
          name: 'description',
          content:
            'All-Courts offers the highest quality athletic court surfacing in the world. Find out what makes them so. Visit our website.'
        },
        {
          name: 'keywords',
          content:
            'tennis court surfaces, pickleball court surface, basketball court surface, sport court surface, outdoor basketball court surface coatings, outdoor court surfaces, premier tennis court surface, tennis court surface price, court surface specialists, indoor basketball court surfaces, court surfaces, badminton court surface, concrete basketball court surface, concrete tennis court surface, court surfaces and repair, multi purpose court surface, outdoor tennis court surfaces, quality courts and surfaces, residential basketball court surface, tennis court surface repair, tennis court surfacing contractors, build outdoor basketball court, build a basketball court, court building, build indoor basketball court, building a tennis court, build bocce ball court, building a pickleball court, court building near me, build outdoor basketball court, companies that build basketball courts, how to build a outdoor shuffleboard court'
        }
      ]
    }
  },
  {
    path: '/pickleball',
    name: 'pickleball',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Pickleball" */ '../views/Pickleball.vue'),
    meta: {
      priority: 0.8,
      title: 'All-Courts | Pickleball Court Construction',
      metaTags: [
        {
          name: 'description',
          content:
            'All-Courts is your all-in-one option for pickleball court construction, surfacing, lighting, and fencing. Visit our website!'
        },
        {
          name: 'keywords',
          content:
            'pickleball court, pickleball courts near me, indoor pickleball courts, indoor pickleball courts near me, outdoor pickleball courts near me, backyard pickleball court, outdoor pickleball courts, pickleball court construction, residential pickleball courts, home pickleball court, building a pickleball court, pickleball court contractors near me, pickleball court resurfacing, tennis pickleball court'
        }
      ]
    }
  },
  {
    path: '/tennis',
    name: 'tennis',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Tennis" */ '../views/Tennis.vue'),
    meta: {
      priority: 0.8,
      title: 'All-Courts | Tennis Court Construction',
      metaTags: [
        {
          name: 'description',
          content:
            'All-Courts is your all-in-one option for tennis court construction, surfacing, lighting, and fencing. Visit our website!'
        },
        {
          name: 'keywords',
          content:
            'tennis court construction, tennis court construction company, tennis court construction near me, tennis court construction companies near me, tennis court construction contractors, tennis hard court construction, indoor tennis court construction, indoor tennis court construction company, home tennis court construction, outdoor tennis court construction, residential tennis court construction, us tennis court construction'
        }
      ]
    }
  },
  {
    path: '/basketball',
    name: 'basketball',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Basketball" */ '../views/Basketball.vue'),
    meta: {
      priority: 0.8,
      title: 'All-Courts | Basketball Court Construction',
      metaTags: [
        {
          name: 'description',
          content:
            'All-Courts is your all-in-one option for basketball court construction, surfacing, lighting, and fencing. Visit our website!'
        },
        {
          name: 'keywords',
          content:
            'outdoor basketball court, outdoor basketball court flooring, home outdoor basketball court, best outdoor basketball courts, best outdoor basketball courts near me, outdoor basketball court installation, build outdoor basketball court, outdoor basketball court design, outdoor basketball court repair, how to make outdoor basketball court, outdoor basketball court installation near me, custom outdoor basketball court, outdoor basketball court installers near me'
        }
      ]
    }
  },
  {
    path: '/volleyball',
    name: 'volleyball',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Volleyball" */ '../views/Volleyball.vue'),
    meta: {
      priority: 0.8,
      title: 'All-Courts | Volleyball Court Construction',
      metaTags: [
        {
          name: 'description',
          content:
            'All-Courts is your all-in-one option for volleyball court construction, surfacing, lighting, and fencing. Visit our website!'
        },
        {
          name: 'keywords',
          content:
            'outdoor volleyball court, outdoor volleyball courts near me, outdoor volleyball court construction, outdoor volleyball court flooring, outdoor volleyball court design, outdoor volleyball court flooring material, volleyball court, volleyball courts near me, beach volleyball court, sand volleyball court, indoor volleyball courts, outdoor volleyball court, home sand volleyball court, home volleyball court, sand volleyball court cost, volleyball court in backyard, volleyball court outside, outdoor volleyball court construction, volleyball court design, sand volleyball court installation, volleyball court installation, olympic volleyball court surface, outdoor volleyball court surfaces'
        }
      ]
    }
  },
  {
    path: '/multicourt',
    name: 'multicourt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "MultiCourt" */ '../views/MultiCourt.vue'),
    meta: {
      priority: 0.8,
      title: 'All-Courts | Multi Sport Court Construction',
      metaTags: [
        {
          name: 'description',
          content:
            'All-Courts is your all-in-one option for multi sport court construction, surfacing, lighting, and fencing. Visit our website!'
        },
        {
          name: 'keywords',
          content:
            'multi functional althletic court, athletic court, multi sport court, backyard multi sport court, indoor multi sport court, multi sport backyard court, multi sport game court, multi purpose outdoor sports courts, outdoor multi sport court, home multi sport court, multi sport court dimensions, sport court, outdoor sport court, how much does a sport court cost, indoor sport court, sport court lighting, basketball sport court, sport court utah, backyard sport court, home sports court, sport court installation, sport court surface, backyard sport court designs, building a sport court, outdoor sport court flooring, outdoor sport court fencing, sport court companies near me'
        }
      ]
    }
  },
  {
    path: '/gallery',
    name: 'gallery',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Gallery" */ '../views/Gallery.vue'),
    meta: {
      priority: 0.7,
      title: 'All-Courts Gallery | Basketball Court | Tennis Court | Pickleball Court Construction',
      metaTags: [
        {
          name: 'description',
          content:
            'Visit the All-Courts gallery! See our basketball court, tennis court, and pickleball court construction right here. '
        },
        {
          name: 'keywords',
          content:
            'sport court, outdoor sport court, how much does a sport court cost, indoor sport court, sport court lighting, basketball sport court, sport court utah, backyard sport court, home sports court, sport court installation, sport court surface, backyard sport court designs, building a sport court, outdoor sport court flooring, outdoor sport court fencing, sport court companies near me'
        }
      ]
    }
  },
  {
    path: '/colors',
    name: 'colors',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ColorPicker" */ '../views/ColorPicker.vue'),
    meta: {
      priority: 0.5,
      title: 'All-Courts Colors | Court Construction | Court Surfacing',
      metaTags: [
        {
          name: 'description',
          content: 'Our crews are highly trained to make sure your cement and acrylic last for years to come.'
        },
        {
          name: 'keywords',
          content:
            'sport court, outdoor sport court, how much does a sport court cost, indoor sport court, sport court lighting, basketball sport court, sport court utah, backyard sport court, home sports court, sport court installation, sport court surface, backyard sport court designs, building a sport court, outdoor sport court flooring, outdoor sport court fencing, sport court companies near me'
        }
      ]
    }
  },
  {
    path: '/lighting',
    name: 'lighting',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Lighting" */ '../views/Lighting.vue'),
    meta: {
      priority: 0.5,
      title: 'Tennis Court Lighting | Basketball Court Lighting | Utah | AZ | All Courts',
      metaTags: [
        {
          name: 'description',
          content:
            'Spend less time chasing and more time playing! Complete your home outdoor basketball court with a strong and durable sports court fence. Click here for a FREE quote!'
        },
        {
          name: 'keywords',
          content:
            'tennis court lighting, basketball court light, basketball court light types, backyard basketball court lighting, pickleball court lights, tennis court solar lighting, tennis court solar lights, volleyball court lighting, tennis court surround lighting, basketball court solar lights, basketball court solar lighting, pickleball court solar lights, pickleball court solar lighting, outdoor basketball court lights, outdoor sport court lighting, pickleball court lighting, sport court lighting, tennis court light design, court lighting, tennis court light requirements, backyard basketball court light, ball court lighting, court lights, outdoor basketball court lighting, retractable tennis court lighting, tennis court light installation, tennis court light repair, tennis court lighting installation, tennis court lighting suppliers'
        }
      ]
    }
  },
  {
    path: '/fencing',
    name: 'fencing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Fencing" */ '../views/Fencing.vue'),
    meta: {
      priority: 0.5,
      title: 'Tennis Court Fencing | Basketball Court Fence | Utah | All Courts',
      metaTags: [
        {
          name: 'description',
          content:
            'Spend less time chasing and more time playing! Complete your home outdoor basketball court with a strong and durable sports court fence. Click here for a FREE quote!'
        },
        {
          name: 'keywords',
          content:
            'tennis court fencing, basketball court fence, basketball court fence netting, backyard basketball court fencing, pickleball court fence, tennis court wire fencing, volleyball court fencing, tennis court surround fencing, basketball court chain link fence, outdoor basketball court fence, outdoor sport court fencing, pickleball court fencing, sport court fencing, tennis court fence design, basketball court containment fencing, court fencing, tennis court fence requirements, backyard basketball court fence, ball court fencing, court fence, outdoor basketball court fencing, retractable tennis court fencing, tennis court fence installation, tennis court fence repair, tennis court fencing installation, tennis court fencing suppliers'
        }
      ]
    }
  },
  {
    path: '/residential',
    name: 'residential',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Residential" */ '../views/Residential.vue'),
    meta: {
      priority: 0.9,
      title: 'All-Courts | Residential Sport Court',
      metaTags: [
        {
          name: 'description',
          content:
            'Ready to start your residential sport court project? All-Courts has expertise in construction, surfacing, lighting, and fencing. Visit our website!'
        },
        {
          name: 'keywords',
          content:
            'residential basketball court, residential pickleball courts, residential tennis court, residential indoor basketball court, residential indoor tennis court, outdoor basketball court residential, residential squash court, basketball court lights residential, residential basketball court installation, residential court, residential outdoor sports courts'
        }
      ]
    }
  },
  {
    path: '/commercial',
    name: 'commercial',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Commercial" */ '../views/Commercial.vue'),
    meta: {
      priority: 0.9,
      title: 'All-Courts | Commercial Sport Court',
      metaTags: [
        {
          name: 'description',
          content:
            'Want to add a sport court for your business? All-Courts has expertise in commercial sport court construction, lighting and more. Visit our website!'
        },
        {
          name: 'keywords',
          content:
            'commercial basketball court, commercial pickleball courts, commercial tennis court, commercial indoor basketball court, commercial indoor tennis court, outdoor basketball court commercial, commercial squash court, basketball court lights commercial, commercial basketball court installation, commercial court, commercial outdoor sports courts'
        }
      ]
    }
  },
  {
    path: '/court-scapes',
    name: 'court-scapes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CourtScapes" */ '../views/CourtScapes.vue'),
    meta: {
      priority: 0.6,
      title: 'Outdoor Court Landscaping | Utah | All Courts',
      metaTags: [
        {
          name: 'description',
          content:
            'Make your new court look like it was planned from the beginning with our court scapes landscaping! Its the details that make the difference!'
        },
        {
          name: 'keywords',
          content:
            'basketball court landscaping, tennis court landscaping, pickleball court landscaping, volleyball court landscaping, outdoor court landscaping, court scapes, court landscaping, sport court landscaping'
        }
      ]
    }
  },
  {
    path: '/careers',
    name: 'Careers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Careers" */ '../views/Careers.vue'),
    meta: {
      priority: 0.5,
      title: 'Careers | Pickleball Resurfacing | Utah | All Courts',
      metaTags: [
        {
          name: 'description',
          content:
            "We are happy to help! Pickleball, Basketball or Tennis court surfacing? We're your go to specialist for all your sport court needs! Call 801-658-5656 for a FREE quote!"
        },
        {
          name: 'keywords',
          content:
            'all-courts customer service, all-courts support, pickleball court service, tennis court service, basketball court service, volleyball court service, multi-sport court service'
        }
      ]
    }
  },
  {
    path: '/plusdiscount',
    name: 'Plus Discount',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Discount" */ '../views/Discount.vue'),
    meta: {
      priority: 0.5,
      title: 'Plus Discount',
      metaTags: [
        {
          name: 'description',
          content:
            'Hometown Plus Manazine Diascount. Get 5% off Acrylic when you book your court through this form or mention this ad. All-Courts builds Pickleball, Tennis, Basketball and Multi-Courts.'
        },
        {
          name: 'keywords',
          content:
            'all-courts customer service, all-courts support, pickleball court service, tennis court service, basketball court service, volleyball court service, multi-sport court service, hometown, hometown plus, discount'
        }
      ]
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue'),
    meta: {
      priority: 0.5,
      title: 'Contact | Pickleball Court Contractor | Utah | All Courts',
      metaTags: [
        {
          name: 'description',
          content:
            "We are happy to help! Pickleball, Basketball or Tennis court surfacing? We're your go to specialist for all your sport court needs! Call 801-658-5656 for a FREE quote!"
        },
        {
          name: 'keywords',
          content:
            'all-courts customer service, all-courts support, pickleball court service, tennis court service, basketball court service, volleyball court service, multi-sport court service'
        }
      ]
    }
  },
  {
    path: '/coupon/:code',
    name: 'Coupon',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Coupon.vue'),
    meta: {
      priority: 0.5,
      title: 'Discount Coupon | Court Builder | Utah | All Courts',
      metaTags: [
        {
          name: 'description',
          content:
            "We are happy to help! Pickleball, Basketball or Tennis court surfacing? We're your go to specialist for all your sport court needs! Call 801-658-5656 for a FREE quote!"
        },
        {
          name: 'keywords',
          content:
            'all-courts customer service, all-courts support, pickleball court service, tennis court service, basketball court service, volleyball court service, multi-sport court service'
        }
      ]
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicy.vue'),
    meta: {
      priority: 0.1,
      title: 'All Courts | Privacy Policy',
      metaTags: [
        {
          name: 'description',
          content: 'privacy policy'
        },
        {
          name: 'keywords',
          content: 'privacy policy'
        }
      ]
    }
  }
]

for (const r in routes) {
  if (hop(routes, r)) {
    const route = routes[r]
    const r0 = route.meta?.metaTags?.[0] || null
    if (r0) {
      route.meta.metaTags.splice(1, 0, {
        property: 'og:description',
        content: r0.content
      })
      const r1 = route.meta.metaTags[2]
      route.meta.metaTags.splice(2, 0, {
        property: 'og:keywords',
        content: r1.content
      })
    }
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
  if (to.path.indexOf('multi-sport') !== -1) {
    next('/multicourt')
    return
  }
  pageTitle(to)
  page(to.path)
  next()
  setTimeout(() => {
    window.scroll(0, 0)
  })
})

export default router
